import { Typography } from '@mui/material';
import { Link } from "react-router-dom";
import useWindowSize from '../../hook/useWindowSize';
import { BoxIcon } from './styles';

export default function ButtonFooter({ link, image, text }) {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width > 600;

    return (
        <Link
            style={{ textDecoration: 'none', color: 'black' }}
            to={link}
        >
            <BoxIcon>
                <Typography variant={isMobile ? 'title3pc' : 'title3mobile'}>
                    {text}
                </Typography>
                <img
                    style={{
                        width: '10vw',
                        height: '10vw',
                        minWidth: '4.8rem',
                        minHeight: '4.8rem',
                        maxWidth: '10.0rem',
                        maxHeight: '10.0rem'
                    }}
                    src={image}
                    alt={`icon ${text}`}
                />
            </BoxIcon>
        </Link>
    );
};
