import { useState } from 'react';
import useWindowSize from '../../hook/useWindowSize';
import logo from '../../imgs/logoOttomar.png';
import Card from '../card';
import { contentData } from '../card/texts';
import Modal from '../modal';
import { BoxCards, BoxCenterCards, TypographyDefault } from './styles';

export default function Cards() {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width > 600;
    const [dataModal, setDataModal] = useState(false);

    return (
        <>
            <BoxCenterCards>
                <img
                    style={{
                        width: isMobile ? '24.8rem' : '20.1rem',
                        height: isMobile ? '20.6rem' : '16.704rem'
                    }}
                    src={logo}
                    alt='logo da Ottomar Madeiras'
                />
                <TypographyDefault variant={isMobile ? 'title1pc' : 'title1mobile'}>
                    CONHEÇA A OTTOMAR MADEIRAS
                </TypographyDefault>
                <TypographyDefault
                    sx={{ textTransform: 'uppercase', maxWidth: '1440px', padding: '0 6.4rem' }}
                    variant={isMobile ? 'title3pc' : windowSize.width > 450 ? 'textCardMobile' : 'littleTextCardMobile'}
                >
                    Atuamos como representante comercial desde 1984, no litoral do Rio Grande Sul, Catarinense e Grande Porto
                    Alegre.
                </TypographyDefault>
                <TypographyDefault variant={isMobile ? 'title2pc' : 'title2mobile'}>
                    CONHEÇA NOSSOS PRODUTOS
                </TypographyDefault>
                <BoxCards ismobile={isMobile ? 'true' : ''}>
                    {contentData.map((data) => (
                        <Card
                            key={data.id}
                            title={data.title}
                            text={data.text}
                            image={data.images[0]}
                            data={data}
                            openModal={setDataModal}
                        />
                    ))}
                </BoxCards>
            </BoxCenterCards>
            {dataModal && (
                <Modal
                    title={dataModal.title}
                    text={dataModal.text}
                    images={dataModal.images}
                    closemodal={setDataModal}
                />
            )}
        </>
    );
};
