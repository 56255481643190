import { BoxImage } from '../styles';
import TextContent from '../textContent';
import { ButtonCard } from './styles';

export default function Card({ data, openModal, image, title, text }) {
    return (
        <ButtonCard onClick={() => openModal(data)}>
            <BoxImage image={image} />
            <TextContent title={title} text={text} />
        </ButtonCard>
    );
};
