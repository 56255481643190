import { createTheme } from '@mui/material'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#8A7258'
        },
        secondary: {
            main: '#A09A42'
        },
        tertiary: '#443727',
        fourth: '#402415',
    },
    typography: {
        title1pc: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '5.6rem',
            lineHeight: '5.6rem'
        },
        title2pc: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '4rem',
            lineHeight: '4rem'
        },
        title3pc: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '2.4rem',
            lineHeight: '2.4rem'
        },
        title4pc: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '2.2rem',
            lineHeight: '2.2rem'
        },
        title1mobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '3.2rem',
            lineHeight: '3.2rem'
        },
        title2mobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '2.2rem',
            lineHeight: '2.2rem'
        },
        title3mobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: '1.6rem'
        },
        title4mobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '1.3rem',
            lineHeight: '1.3rem'
        },
        textCardMobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '2.2rem',
            lineHeight: '2.2rem'
        },
        titleCardMobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '2.8rem',
            lineHeight: '2.8rem'
        },
        littleTextCardMobile: {
            fontFamily: 'DM Sans',
            fontWeight: 600,
            fontSize: '1.8rem',
            lineHeight: '1.8rem'
        }
    }
})