import Cards from './components/cards';
import Footer from './components/footer';
import Header from './components/header';

function App() {

  return (
    <>
      <Header />
      <Cards />
      <Footer />
    </>

  );
}

export default App;
