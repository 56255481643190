import { Typography } from '@mui/material';
import useWindowSize from '../../hook/useWindowSize';
import cellIcon from '../../imgs/icons/cellIcon.png';
import emailIcon from '../../imgs/icons/emailIcon.png';
import facebookIcon from '../../imgs/icons/facebookIcon.png';
import instagramIcon from '../../imgs/icons/instagramIcon.png';
import whatsappIcon from '../../imgs/icons/whatsappIcon.png';
import ButtonFooter from '../buttonFooter';
import { BoxColor } from '../styles';
import { BoxCenterFooter } from './styles';

export default function Footer() {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width > 600;

    return (
        <BoxColor id='contato'>
            <Typography paddingTop='3.2rem' variant={isMobile ? 'title2pc' : 'title2mobile'}>
                CONTATO
            </Typography>
            <BoxCenterFooter windowsize={windowSize.width}>
                <ButtonFooter
                    link='tel:+51984026519'
                    text='Telefone'
                    image={cellIcon}
                />
                <ButtonFooter
                    link='https://api.whatsapp.com/send?phone=5551984026519&text=Ol%C3%A1%2C+gostaria+de+contatar+sobre+seus+produtos'
                    text="Whats'App"
                    image={whatsappIcon}
                />
                <ButtonFooter
                    link='https://www.instagram.com/ottomar.madeiras/'
                    text='Instagram'
                    image={instagramIcon}
                />
                <ButtonFooter
                    link='https://www.facebook.com/profile.php?id=100092689917734'
                    text='Facebook'
                    image={facebookIcon}
                />
                <ButtonFooter
                    link='https://mail.google.com/mail/u/0/?fs=1&tf=cm&source=mailto&su=Ol%C3%A1%2C+gostaria+de+contatar+sobre+seus+produtos&to=comercial@ottomarmadeiras.com'
                    text='Email'
                    image={emailIcon}
                />
            </BoxCenterFooter>
        </BoxColor>
    );
};
