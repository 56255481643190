import { Typography } from '@mui/material';
import useWindowSize from '../../hook/useWindowSize';
import { BoxCenterText, TypographyStyled } from './styles';

export default function TextContent({ title, text, modal }) {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width > 600;
    console.log(modal)

    return (
        <BoxCenterText modal={modal} ismobile={isMobile ? 'true' : ''}>
            <Typography variant={isMobile ? 'title2pc' : windowSize.width > 450 ? 'titleCardMobile' : 'textCardMobile'}>
                {title}
            </Typography>
            <TypographyStyled
                modal={modal}
                variant={isMobile ? 'title3pc' : windowSize.width > 450 ? 'textCardMobile' : 'littleTextCardMobile'}
            >
                {text}
            </TypographyStyled>
            {!modal && (
                <Typography
                    color={'rgb(0, 0, 238)'}
                    variant={isMobile ? 'title3pc' : windowSize.width > 450 ? 'textCardMobile' : 'littleTextCardMobile'}
                >
                    Veja Mais
                </Typography>
            )
            }
        </BoxCenterText>
    );
};
