import { Button, styled } from '@mui/material';

const ButtonCard = styled(Button)`
    color: black;
    text-transform: none;
    max-width: 64rem;
    width: 100%;
    padding: 1.6rem;
    border-radius: 1.6rem;
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    box-shadow: 0 0 1.6rem #00000020;
`;

export {
    ButtonCard,
}