import { Box, styled } from '@mui/material';

const BoxImage = styled(Box)`
    width: 50%;
    position: relative;
    background-image: url(${(props) => (props.image)});
    background-size: cover;
    background-position: center;

    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
`;

const BoxColor = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => (props.theme.palette.primary.main)};
    flex-direction: column;
    gap: 1.6rem;
`;

const BoxCenterData = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: ${({ windowsize }) => (windowsize > 800 ? '1.6rem 6.4rem' : '1.6rem')};
    max-width: 1440px;
    width: 100%;    
    gap: 1.6rem;
    flex-wrap: wrap;
`;

export {
    BoxImage,
    BoxColor,
    BoxCenterData
}