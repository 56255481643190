import { Box, styled, Typography } from '@mui/material';

const BoxCenterText = styled(Box)`
    width: ${(({ ismobile, modal }) => ((modal === 'true') & (ismobile === '') ? '100%' : '50%'))};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: ${(({ ismobile }) => (ismobile ? '1.6rem' : '0.8rem'))};
`;

const TypographyStyled = styled(Typography)`
    display: ${(({ modal }) => (modal === 'true' ? 'flex' : '-webkit-box'))};
    -webkit-line-clamp: ${(({ modal }) => (modal === 'true' ? '' : '3'))};
    -webkit-box-orient: ${(({ modal }) => (modal === 'true' ? '' : 'vertical'))};
    overflow: ${(({ modal }) => (modal === 'true' ? '' : 'hidden'))};
`;

export {
    BoxCenterText,
    TypographyStyled
}