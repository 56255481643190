import { Link, Typography } from '@mui/material';
import useWindowSize from '../../hook/useWindowSize';
import logo from '../../imgs/logoOttomar.png';
import { BoxColor } from '../styles';
import { BoxCenterHeader, BoxHeader, BoxLogo } from './styles';

export default function Header() {
    const windowSize = useWindowSize();
    const isMobile = windowSize.width > 600;

    return (
        <BoxColor>
            <BoxHeader windowsize={windowSize.width}>
                <BoxLogo>
                    <BoxCenterHeader>
                        <Typography color='fourth' variant={isMobile ? 'title1pc' : 'title1mobile'}>
                            OTTOMAR
                        </Typography>
                        <Typography color='fourth' variant={isMobile ? 'title4pc' : 'title4mobile'}>
                            TRADIÇÃO EM MADEIRAS
                        </Typography>
                    </BoxCenterHeader>
                    <img
                        style={{
                            width: isMobile ? '9.9rem' : '3.758rem',
                            height: isMobile ? '7.903rem' : '3.0rem'
                        }}
                        src={logo}
                        alt='Logo da Empresa'
                    />
                </BoxLogo>
                <Link href='#contato' color='fourth'>
                    <Typography
                        color='fourth'
                        variant={isMobile ? 'title4pc' : 'title3mobile'}
                    >
                        CONTATO
                    </Typography>
                </Link>
            </BoxHeader>
        </BoxColor>
    );
}
