import { Box, styled } from '@mui/material';
import { BoxCenterData } from "../styles";

const BoxHeader = styled(BoxCenterData)`
    @media screen and (max-width: 355px) {
        justify-content: center;
    }
`;

const BoxLogo = styled(Box)`
    display: flex;
    gap: 0.8rem;
    align-items: center;
`;

const BoxCenterHeader = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export {
    BoxCenterHeader,
    BoxHeader,
    BoxLogo
};
