import cedrinho1 from '../../imgs/cedrinho/cedrinho-1.jpg';
import cedrinho2 from '../../imgs/cedrinho/cedrinho-2.jpg';
import cedrinho3 from '../../imgs/cedrinho/cedrinho-3.jpg';
import garapeira1 from '../../imgs/garapeira/garapeira-1.jpg';
import garapeira2 from '../../imgs/garapeira/garapeira-2.jpg';
import garapeira3 from '../../imgs/garapeira/garapeira-3.jpg';
import ipecumaru1 from '../../imgs/ipecumaru/ipecumaru-1.jpeg';
import ipecumaru2 from '../../imgs/ipecumaru/ipecumaru-2.jpeg';
import itauba1 from '../../imgs/itauba/itauba-1.jpg';
import itauba2 from '../../imgs/itauba/itauba-2.jpg';
import sucupira1 from '../../imgs/sucupira/sucupira-1.png';
import sucupira2 from '../../imgs/sucupira/sucupira-2.png';
import tauari1 from '../../imgs/tauari/tauari-1.webp';
import tauari2 from '../../imgs/tauari/tauari-2.jpg';


const contentData = [{
    id: 1,
    text: 'Uma madeira leve, fosca, de tom castanho-avermelhado e cheiro imperceptível, proporcionando charme e aconchego.',
    title: 'Cedrinho',
    images: [cedrinho1, cedrinho2, cedrinho3]
}, {
    id: 2,
    text: 'Uma madeira bastante densa, pesada, resistente apresenta uma superfície lustrosa e lisa e com tom castanho-amarelado.',
    title: 'Garapeira',
    images: [garapeira1, garapeira2, garapeira3]
}, {
    id: 3,
    text: 'Uma madeira extremamente forte, densa, resistente e durável, com sua tonalidade marrom, sendo perfeita desde a estruturação até o acabamento.',
    title: 'Ipê Cumaru',
    images: [ipecumaru1, ipecumaru2]
}, {
    id: 4,
    text: 'Uma madeira relativamente densa e pesada, bastante resistente, lisa ao toque porém levemente fosca e cheiro adocicado.',
    title: 'Itaúba',
    images: [itauba1, itauba2]
}, {
    id: 5,
    text: 'Uma madeira densa, com tom de chocolate, bastante resistente a pragas, com um apelo estético bastante rústico.',
    title: 'Sucupira',
    images: [sucupira1, sucupira2]
}, {
    id: 6,
    text: 'Uma madeira leve, perfeita para ambientes internos, por sua delicadeza e tom bege amarelado mais claro e sutil.',
    title: 'Tauari',
    images: [tauari1, tauari2]
}];

export {
    contentData
};
