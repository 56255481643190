import { Box, Typography, styled } from '@mui/material';

const BoxCards = styled(Box)`
    max-width: 1440px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.6rem;
    padding: ${({ ismobile }) => (ismobile ? '0 6.4rem' : '0 1.6rem')};
`;

const BoxCenterCards = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 3.2rem;
    padding-top: 3.2rem;
    padding-bottom: 3.2rem;
`;

const TypographyDefault = styled(Typography)`
    text-align: center;
    padding: 0 1.6rem;
`;

export {
    BoxCards,
    BoxCenterCards,
    TypographyDefault
}