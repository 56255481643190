import { styled } from "@mui/material";
import { BoxCenterData } from "../styles";

const BoxCenterFooter = styled(BoxCenterData)`
    @media screen and (max-width: 655px) {
        justify-content: center;
    }
`;

export {
    BoxCenterFooter
};
