import { Box, Typography, styled } from '@mui/material';

const BoxModal = styled(Box)`
    width: 100vw;
    height: 100vh;
    background-color: #00000040;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const BoxContent = styled(Box)`
    background-color: white;
    margin: 1.6rem;
    margin-right: 3.1rem;
    max-width: 89.6rem;
    width: 100vw;
    position: relative;
    padding: 1.6rem;
    padding-bottom: 3.2rem;
    border-radius: 1.6rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3.2rem;
`;

const BoxClose = styled(Box)`
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0.8rem;
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
    border-radius: 0.6rem;
    height: 4.8rem;

    &:hover {
        cursor: pointer;
    }
`;

const BoxCarousel = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.8rem;
    width: 100%;
`;

const TypographyClose = styled(Typography)`
    font-size: 3.2rem;
    font-weight: 500;
`;

export {
    BoxCarousel, BoxClose, BoxContent, BoxModal, TypographyClose
};
