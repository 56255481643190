import { useState } from 'react';
import { BoxImage } from '../styles';
import TextContent from '../textContent';
import { BoxCarousel, BoxClose, BoxContent, BoxModal, TypographyClose } from './styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Modal({ images, text, title, closemodal }) {
    const [indexData, setIndexData] = useState(0);

    function handleIndexData(more) {
        if (more) {
            if (indexData === (images.length - 1)) {
                return setIndexData(0)
            }
            return setIndexData(indexData + 1)
        }

        if (indexData === 0) {
            return setIndexData(images.length - 1)
        }
        return setIndexData(indexData - 1)
    }

    return (
        <BoxModal>
            <BoxContent>
                <BoxClose onClick={() => closemodal(false)}>
                    <TypographyClose>
                        X
                    </TypographyClose>
                </BoxClose>
                <BoxCarousel>
                    <ArrowBackIosIcon
                        onClick={() => handleIndexData(false)}
                        sx={{
                            width: '3.2rem',
                            height: '3.2rem',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                    <BoxImage image={images[indexData]} />
                    <ArrowForwardIosIcon
                        onClick={() => handleIndexData(true)}
                        sx={{
                            width: '3.2rem',
                            height: '3.2rem',
                            '&:hover': {
                                cursor: 'pointer'
                            }
                        }}
                    />
                </BoxCarousel>
                <TextContent modal='true' title={title} text={text} />
            </BoxContent>
        </BoxModal>
    );
};
