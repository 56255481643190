import { Box, styled } from "@mui/material";

const BoxIcon = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
`;

export {
    BoxIcon
}